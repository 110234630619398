    <template>
        <div class="home">

            <img class='background-img' src='../../../assets/images/bg.jpg' alt=""/>
            <div class='card-wrapper'>
                <div class="top-banner mb-5">
                    <img src="../../../assets/images/logo.png" alt="">
                </div>

                <div class="container-fluid" v-if="mount">
                    <div>
                        <loading-animation></loading-animation>
                        <span class="m-2">Loading the data from the server</span>
                    </div>
                </div>

                <div class="container-fluid" v-else>
                    <div class="row">
                        <div class="col">
                            <s-form role="form" @submit="onSubmit">

                                <validated-input v-model="model.otp" type="text" shadow="0" :rules="{required:true}"
                                                 placeholder="" label="Enter your otp" name = "otp"/>

                                <!-- timer added 06-12-2024                           -->
                                <div v-if="timer > 0" class="timer">
                                    <span>OTP will expire in: {{ timer }} seconds</span>
                                    <button type="submit" class="xeo-btn" v-if="show_verify_button">
                                    <span v-if="loading">
                                        <loading-animation/> Validating Otp..
                                    </span>
                                    <span v-else>
                                        Verify Otp
                                    </span>
                                </button>
                                </div>
                                <div v-else>
                                    <button class="xeo-btn" @click="resendOtp">Resend OTP</button>
                                </div>

                                <div class="card bg-danger mb-2" v-if="errorMessage" v-html="errorMessage"></div>

<!--                                <button type="submit" class="xeo-btn" v-if="show_verify_button">-->
<!--                                    <span v-if="loading">-->
<!--                                        <loading-animation/> Validating Otp..-->
<!--                                    </span>-->
<!--                                    <span v-else>-->
<!--                                        Verify Otp-->
<!--                                    </span>-->
<!--                                </button>-->
                            </s-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';
import { mapActions } from 'vuex';

export default {
    name  : 'VerifyOtp',
    props : {
        info_message : {
            type    : String,
            default : ''
        }
    },

    data () {
        return {
            user_id            : this.$route.params.user_id,
            loading            : false,
            mount              : true,
            errorMessage       : '',
            // added timer
            timer              : 60,
            show_verify_button : true,
            // -------------------------------------------------
            model              : {
                otp  : '',
                user : ''

            }
        };
    },

    mounted () {
        this.loadData();
        //    start the timer when the component is mounted
        this.startTimer();
        //    -----------------------------------------------
    },

    methods : {
        ...mapActions(['setUser']),
        async onSubmit () {
            try {
                const that = this;
                this.loading = true;
                this.model.user = this.user_id;

                const response = await axios.form(urls.auth.otpverify, this.model);
                const json = response.data;
                if (json.error === false) {
                    that.loading = false;
                    that.setUser(json.user);
                    that.$router.push('/').then(() => {
                        window.location.reload(true);
                    });
                } else {
                    that.loading = false;
                    this.errorMessage = json.message;
                }
            } catch (error) {
                this.errorMessage =
                        'There was some errors while communicating <br/>with the server, Please try again later.';
                this.model.otp = '';
                this.model.user = '';
                this.loading = false;
            }
        },

        async loadData () {
            const response = await axios.get(urls.auth.status);
            console.log('response : ', response.data);
            if (response.data['public-key']) {
                this.mount = false;
            }
        },

        startTimer () {
            if (this.timer > 0) {
                this.show_verify_button = true;
                const timerInterval = setInterval(() => {
                    if (this.timer > 0) {
                        this.timer--;
                    } else {
                        this.model.otp = '';
                        this.show_verify_button = false;
                        clearInterval(timerInterval);
                        // Stop the timer when it reaches 0
                    }
                }, 1000);
            }
        },

        async resendOtp () {
            try {
                const response = await axios.post(urls.auth.resendotp, { user_id : this.user_id });

                if (response.data.success) {
                    this.timer = 60; // Reset the timer to 60 seconds

                    this.startTimer();
                } else {
                    this.errorMessage = 'Failed to resend OTP. Please try again.';
                }
            } catch (error) {
                this.errorMessage = 'Error resending OTP. Please check your connection.';
            }
        }
    }
};

</script>

    <style scoped>

    </style>
